import React, { useState } from 'react'
import Header from './components/header'
import GetBenefit from './pages/getBenefit'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import ConfirmBenefit from './pages/confirmBenefit'
import Confirmation from './pages/confirmation'
import Login from './pages/login'

function App () {
  const [data, setData] = useState({
    code: '',
    benefit: '',
    token: null,
    confirmed: false
  })

  function updateData (type, newData) {
    setData((data) => {
      return { ...data, [type]: newData }
    })
  }

  return (
    <BrowserRouter>
      <div className="layout u-bg-primary">
        <Header></Header>
        <div className="c-layout">
          <div className='c-popup__holder o-scrollable o-align-middle o-wrapper o-section'>
            <Routes>
              <Route
                path="/"
                element={data.token ? <Navigate to="/benefit" /> : <Login updateData={updateData}/>}
              />
              <Route
                path="/benefit"
                element={data.token
                  ? data.confirmed
                    ? <Confirmation confirmed={data.confirmed} updateData={updateData}/>
                    : data.benefit
                      ? <ConfirmBenefit benefit={data.benefit} code={data.code} token={data.token} updateData={updateData}/>
                      : <GetBenefit token={data.token} updateData={updateData} />
                  : <Navigate to="/" />
                }
              />
            </Routes>
          </div>
        </div>
        <div className='footer'>
          <span className="footer-logo footer-logo--freezone"></span>
        </div>
        <span className="c-layout-deco top"></span>
        <span className="c-layout-deco bottom"></span>

      </div>
    </BrowserRouter>
  )
}

export default App
