import axios from 'axios'

export default class ApiService {
  constructor () {
    this.client = null
    this.api_url = process.env.REACT_APP_API_ENDPOINT
  }

  init () {
    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 30000
    })

    return this.client
  };

  getBenefitByCode (token, benefitCode) {
    return this.init().get(
      'get-benefit-by-code',
      {
        params: { code: benefitCode },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
  };

  confirmBenefitByCode (token, benefitCode) {
    return this.init().post(
      'store-confirm-benefit',
      { code: benefitCode },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
  };

  login (username, password) {
    return this.init().post(
      'oauth', {
        username,
        password,
        client_id: 'app',
        grant_type: 'password',
        oauth_type: '3'
      }
    )
  };
}
