import React, { useState } from 'react'
import ApiService from '../helper/api'
import PropTypes from 'prop-types'

export default function GetBenefit ({ token, updateData }) {
  const [code, setCode] = useState()
  const [error, setError] = useState({
    requiredInput: '',
    requestResponse: ''
  })
  const [loading, setLoading] = useState(false)
  const api = new ApiService()

  const getBenefit = () => {
    if (code) {
      setLoading(true)
      api.getBenefitByCode(token, code).then(response => {
        if (response.data) {
          updateData('code', code)
          updateData('benefit', response.data)
        }
      }).catch(error => {
        if (error.response.data.detail) {
          setError({
            requestResponse: error.response.data.detail
          })
        } else {
          setError({
            requestResponse: 'Ha ocurrido un error inesperado'
          })
        }
        setLoading(false)
      })
    } else {
      setError({
        requiredInput: '* Debe ingresar el código'
      })
      setLoading(false)
    }
  }

  const handleChange = event => {
    setCode(event.target.value)
  }

  return (

        <div className="c-popup__content o-align-middle__aligner">
            <h1 className="title">Canje de beneficios</h1>
            <div className="c-event">
                <div className="c-event__info">
                    <div className="c-form__group">
                        <label className="c-form__label">
                            <span className="c-form__label-hint">Ingresar código</span>
                            <input onChange={handleChange} id="code" name="code" type="text" className="c-form__input__small" placeholder="Ingrese el código" />
                        </label>
                    </div>
                    <span style={{ display: error.requiredInput ? 'block' : 'none' }} className="c-form__error">{error.requiredInput}</span>
                </div>
                <button onClick={getBenefit} className={'c-btn c-btn--center c-btn--mt btn-color ' + (loading ? 'c-btn--loading' : '')}>
                  <span className='c-btn__text'>Continuar</span>
                </button>
                <span style={{ display: error.requestResponse ? 'block' : 'none' }} className="c-form__error">{error.requestResponse}</span>
            </div>
        </div>
  )
}
GetBenefit.propTypes = {
  token: PropTypes.string,
  updateData: PropTypes.bool
}
