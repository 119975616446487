import React from 'react'
import PropTypes from 'prop-types'

export default function Confirmation ({ updateData }) {
  const back = () => {
    updateData('benefit', '')
    updateData('confirmed', false)
    updateData('code', '')
  }

  return (
        <div className="c-popup__content o-align-middle__aligner">
            <div className="c-popup__content o-align-middle__aligner">
                <h1 className="title">¡confirmado!</h1>
                <p className="c-event__text description">El beneficio fue confirmado exitosamente.</p>
                <button onClick={back} className="c-btn c-btn--mt btn-color">
                  <span className='c-btn__text'>Volver</span>
                </button>
            </div>
        </div>
  )
}
Confirmation.propTypes = {
  updateData: PropTypes.bool
}
