import React, { useState } from 'react'
import ApiService from '../helper/api'
import PropTypes from 'prop-types'

export default function Login ({ updateData }) {
  const [username, setUsername] = useState()
  const [password, setPassword] = useState()
  const [error, setError] = useState({
    requiredInput: '',
    requestResponse: ''
  })
  const [loading, setLoading] = useState(false)
  const api = new ApiService()

  const login = () => {
    if (username && password) {
      setLoading(true)
      api.login(username, password).then(response => {
        if (response.data) {
          updateData('token', response.data.access_token)
        }
      }).catch(error => {
        if (error.response.status === 401) {
          setError({
            requestResponse: 'El nombre de usuario o contraseña no es correcta.'
          })
        } else if (error.response.data.detail) {
          setError({
            requestResponse: error.response.data.detail
          })
        } else {
          setError({
            requestResponse: 'Ha ocurrido un error al iniciar sesión.'
          })
        }
        setLoading(false)
      })
    } else {
      setError({
        requiredInput: '* Todos los campos son requeridos'
      })
      setLoading(false)
    }
  }

  const handleUsernameChange = event => {
    setUsername(event.target.value)
  }
  const handlePasswordChange = event => {
    setPassword(event.target.value)
  }

  return (

        <div className="c-popup__content o-align-middle__aligner">
            <h1 className="title">Iniciar sesión</h1>
            <div className="c-event">
                <div className="c-event__info">
                    <div className="c-form__group">
                        <label className="c-form__label">
                            <span className="c-form__label-hint">Nombre de usuario de la tienda</span>
                            <input onChange={handleUsernameChange} id="username" name="username" type="text" className="c-form__input__small" placeholder="Ingrese el usuario" />
                        </label>
                    </div>
                    <div className="c-form__group">
                        <label className="c-form__label">
                            <span className="c-form__label-hint">Clave</span>
                            <input onChange={handlePasswordChange} id="password" name="password" type="password" className="c-form__input__small" placeholder="Ingrese la clave" />
                        </label>
                    </div>
                    <span style={{ display: error.requiredInput ? 'block' : 'none' }} className="c-form__error">{error.requiredInput}</span>
                </div>
                <button onClick={login} className={'c-btn c-btn--center c-btn--mt btn-color ' + (loading ? 'c-btn--loading' : '')}>
                  <span className='c-btn__text'>Continuar</span>
                </button>
                <span style={{ display: error.requestResponse ? 'block' : 'none' }} className="c-form__error">{error.requestResponse}</span>
            </div>
        </div>
  )
}
Login.propTypes = {
  updateData: PropTypes.bool
}
