import React, { useState } from 'react'
import ApiService from '../helper/api'
import PropTypes from 'prop-types'

export default function ConfirmBenefit ({ code, benefit, token, updateData }) {
  const [loading, setLoading] = useState(false)
  const [imageLoading, setImageLoading] = useState(true)

  const api = new ApiService()
  const [error, setError] = useState()
  const confirm = () => {
    setLoading(true)
    api.confirmBenefitByCode(token, code).then(response => {
      if (response.data && response.data.success) {
        updateData('confirmed', true)
      } else {
        setError(
          'Ha ocurrido un error inesperado'
        )
        setLoading(false)
      }
    }).catch(error => {
      if (error.response.data.detail) {
        setError(
          error.response.data.detail
        )
      } else {
        setError(
          'Ha ocurrido un error inesperado'
        )
      }
      setLoading(false)
    })
  }

  const back = () => {
    updateData('benefit', '')
    updateData('code', '')
    updateData('confirmed', false)
  }

  return (

        <div className="c-popup__content o-align-middle__aligner">
            <h1 className="title">confirmar beneficio</h1>
            <div className="c-event">
                {/* Add className ".c-item__image--loading" to show loader or  ".c-item__image--notfound" to show default picture */}
                <div className="c-event__image-holder">
                  <div className={'c-event__image ' + (!benefit.picture ? 'c-item__image--notfound' : imageLoading ? 'c-item__image--loading' : '')}>
                    {/* Item image loader */}
                    <span className="c-item__image__loader"></span>
                    {/* "No image" default picture */}
                    <span className="c-item__image__notfound icon-image"></span>
                    { benefit.picture &&
                    <picture className="c-pic c-pic--cover">
                        <img className="c-pic__img" src={'https://freezone-images.s3.amazonaws.com/images/' + benefit.picture.filename} alt={benefit.picture.filename} onLoad={() => setImageLoading(false)}/>
                    </picture>
                    }
                  </div>
                </div>
                <div className="c-event__info">
                    <p className="c-event__text description">{benefit.title}</p>
                    { benefit.discount && <h3 className="c-event__name discount">{benefit.discount}% <sup>OFF</sup></h3> }
                    <p className="c-event__text">{benefit.store.name}</p>
                </div>
                <button onClick={back} className="c-btn c-btn--mt btn-light">
                  <span className='c-btn__text'>Volver</span>
                </button>
                <button onClick={confirm} className={'c-btn c-btn--mt btn-color ' + (loading ? 'c-btn--loading' : '')}>
                  <span className='c-btn__text'>Confirmar</span>
                </button>
                <span style={{ display: error ? 'block' : 'none' }} className="c-form__error">{error}</span>
            </div>
        </div>
  )
}
ConfirmBenefit.propTypes = {
  code: PropTypes.string,
  token: PropTypes.string,
  benefit: PropTypes.shape({
    title: PropTypes.string,
    discount: PropTypes.string,
    store: {
      name: PropTypes.string
    },
    picture: PropTypes.shape({
      filename: PropTypes.string
    })
  }),
  updateData: PropTypes.bool
}
